import http, { getAuthHeader } from './index';

export const getAcademy = ({ id } = { id: 'me' }) =>
  http({
    method: 'GET',
    url: `/academies_student/${id}/`,
    headers: {
      ...getAuthHeader(),
    },
  }).then(({ data }) => data);

export const getStudentGroup = (
  { academy, id } = { academy: 'me', id: 'me' },
) =>
  http({
    method: 'GET',
    url: `/academies_student/${academy}/student_groups/${id}/`,
    headers: {
      ...getAuthHeader(),
    },
  }).then(({ data }) => data);

export const getStudentGroupUser = (
  { academy, studentGroup, id } = {
    academy: 'me',
    studentGroup: 'me',
    id: 'me',
  },
) =>
  http({
    method: 'GET',
    url: `/academies_student/${academy}/student_groups/${studentGroup}/student_group_users/${id}/`,
    headers: {
      ...getAuthHeader(),
    },
  }).then(({ data }) => data);

export const createStudentGroupUser = payload =>
  http({
    method: 'POST',
    url: `/academies_student/${payload?.academy?.id ||
      'me'}/student_groups/${payload?.studentGroup?.id ||
      'me'}/student_group_users/${payload.id || 'me'}/`,
    headers: {
      ...getAuthHeader(),
    },
    data: payload,
  }).then(({ data }) => data);

export const updateStudentGroupUser = payload =>
  http({
    method: 'PATCH',
    url: `/academies_student/${payload?.academy?.id ||
      'me'}/student_groups/${payload?.studentGroup?.id ||
      'me'}/student_group_users/${payload.id || 'me'}/`,
    headers: {
      ...getAuthHeader(),
    },
    data: payload,
  }).then(({ data }) => data);

export const deleteStudentGroupUser = (
  { academy, studentGroup, id } = {
    academy: 'me',
    studentGroup: 'me',
    id: 'me',
  },
) =>
  http({
    method: 'DELETE',
    url: `/academies_student/${academy}/student_groups/${studentGroup}/student_group_users/${id}/`,
    headers: {
      ...getAuthHeader(),
    },
  }).then(({ data }) => data);

export const getMessages = params =>
  http({
    method: 'GET',
    url: '/regions/messages/',
    headers: {
      ...getAuthHeader(),
    },
    params,
  });

export const joinStudentGroup = ({ params, data }) =>
  http({
    method: 'POST',
    url: '/academies_student/me/join_student_group/',
    headers: {
      ...getAuthHeader(),
    },
    params,
    data,
  }).then(res => res.data);

export const getMyAcademyStudentGroups = () =>
  http({
    method: 'GET',
    url: `/academies_student/me/student_groups/`,
    headers: { ...getAuthHeader() },
  });

export default {
  getAcademy,
  getStudentGroup,
  getStudentGroupUser,
  createStudentGroupUser,
  updateStudentGroupUser,
  deleteStudentGroupUser,
  getMessages,
  joinStudentGroup,
};
