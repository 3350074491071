import React, { Suspense } from 'react';
import { debug as DEBUG } from 'utils/_vars';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import loadingBackgroundImage from 'assets/defaultBg.jpg';
import PageLoader from 'components/common/PageLoader';

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 100000000,
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    filter: 'blur(8px)',
  },
  message: {
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%)',
    textShadow: '0px 0px 6px white',
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '30%',
    transform: 'translate(-50%)',
    width: 300,
    height: 24,
    borderLeft: [[8, 'solid', theme.palette.secondary.main]],
    borderRight: [[8, 'solid', theme.palette.secondary.main]],
    overflow: 'hidden',
  },
  ball: {
    height: 8,
    width: 0,
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    margin: 0,
    '&:nth-child(1)': {
      animation: '$ball 2s ease-in-out infinite',
    },
    '&:nth-child(2)': {
      animation: '$ball 2s ease-in-out  0.12s infinite',
    },
    '&:nth-child(3)': {
      animation: '$ball 2s ease-in-out  0.24s infinite',
    },
  },

  '@keyframes ball': {
    '0%': {
      left: 0,
      width: 0,
    },
    '25%': {
      left: 0,
      width: 301,
    },
    '50%': {
      left: 301,
      width: 0,
    },
    '75%': {
      left: 0,
      width: 301,
    },
    '100%': {
      left: 0,
      width: 0,
    },
  },
}));

// eslint-disable-next-line react/prop-types
function LoadingPageFunc({ message, debug }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dots = '...';
  let loading = t('Loading');
  loading = loading.concat(dots);

  return (
    <div className={classes.container}>
      <img
        src={loadingBackgroundImage}
        alt="pg"
        className={classes.background}
      />

      <Typography variant="h5" color="secondary" className={classes.message}>
        {DEBUG && debug} {message || loading}
      </Typography>

      <div className={classes.loader}>
        <div className={classes.ball} />
        <div className={classes.ball} />
        <div className={classes.ball} />
      </div>
    </div>
  );
}

export default function LoadingPage() {
  const classes = useStyles();
  return (
    <Suspense
      fallback={
        <img
          src={loadingBackgroundImage}
          alt="pg"
          className={classes.background}
        />
      }
    >
      <PageLoader open fullPageLoader disableSpinner />
      {/*
      <LoadingPageFunc />
      */}
    </Suspense>
  );
}
