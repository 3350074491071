/* eslint-disable no-param-reassign */
import { action, thunkOn, thunk, computed } from 'easy-peasy';

// studentGroupService;

const initialState = {
  academy: null,
  studentGroup: null,
  studentGroupUser: null,
  error: null,
};

export default {
  ...initialState,
  academyAddressString: computed(({ academy }) =>
    academy
      ? [
          academy.address_road,
          academy.address_postalcode,
          academy.address_city,
          academy.address_country,
        ]
          .filter(e => Boolean(e))
          .join(', ')
      : ' – ',
  ),
  setStudentGroupUser: action((state, payload) => {
    state.studentGroupUser = payload;
    state.error = null;
  }),
  setStudentGroup: action((state, payload) => {
    state.studentGroup = payload;
    state.error = null;
  }),
  setAcademy: action((state, payload) => {
    state.academy = payload;
    state.error = null;
  }),

  setAcademyMessages: action((state, payload) => {
    state.academy.messages = payload;
    state.error = null;
  }),

  init: thunkOn(
    // targetResolver:
    (actions, storeActions) => storeActions.sector.getSector,
    // handler:
    async (actions, target, { injections }) => {
      // if academy sectror, get academy data
      if (target?.result?.payload?.is_academy) {
        injections.academyService.getAcademy().then(actions.setAcademy);
      }
    },
  ),
  getAcademyMessages: thunkOn(
    actions => actions.setAcademy,
    async (actions, target, { injections }) => {
      // if academy sectror, get student_group data
      injections.academyService
        .getMessages()
        .then(({ data }) => actions.setAcademyMessages(data));
    },
  ),
  getStudentGroup: thunkOn(
    actions => actions.setAcademy,
    async (actions, target, { injections }) => {
      // if academy sectror, get student_group data
      injections.academyService
        .getStudentGroup()
        .then(actions.setStudentGroup)
        .catch(error => {
          console.log(error);
        });
    },
  ),
  getStudentGroupUser: thunkOn(
    actions => actions.setStudentGroup,
    async (actions, target, { injections }) => {
      // if academy sectror, get student_group_user data
      injections.academyService
        .getStudentGroupUser()
        .then(actions.setStudentGroupUser);
    },
  ),

  updateStudentGroupUser: thunk((actions, payload, { injections }) =>
    injections.academyService
      .updateStudentGroupUser(payload)
      .then(actions.setStudentGroupUser),
  ),
  updateStudentGroup: thunk((actions, payload, { injections }) =>
    injections.academyService
      .joinStudentGroup(payload)
      .then(actions.setStudentGroup),
  ),
};
