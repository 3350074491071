import React, { Fragment, useContext } from 'react';
import cl from 'clsx';
import FlagIcon from 'react-country-flag';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import AppContext from 'context/App';

// <editor-fold desc="LanguageSelector style definition ...">
const useStyles = makeStyles(theme => {
  return {
    selectedLng: {
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  };
});
// </editor-fold>

// <editor-fold desc="LanguageSelector type definitions ...">
// </editor-fold>

// <editor-fold desc="LanguageSelector component definition ...">
const LanguageSelector = (): JSX.Element => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { isMobile } = useContext<any>(AppContext);

  const getLangCode = (lng: string): string => {
    if (lng === 'en-GB') {
      return 'gb';
    }
    if (lng === 'sv') {
      return 'se';
    }
    return lng;
  };

  const changeLanguage = (lng: string) => (): void => {
    i18n.changeLanguage(lng);
  };

  console.debug('i18n', i18n);

  return (
    <Fragment>
      {i18n.languages.sort().map(lng => (
        <IconButton
          key={lng}
          className={cl({
            [classes.selectedLng]: lng === i18n.language,
          })}
          onClick={changeLanguage(lng)}
        >
          <FlagIcon
            svg
            code={getLangCode(lng)}
            styleProps={{
              width: isMobile ? '18px' : '24px',
              height: isMobile ? '18px' : '24px',
            }}
          />
        </IconButton>
      ))}
    </Fragment>
  );
};
// </editor-fold>

export default LanguageSelector;
