import http, { getAuthHeader } from './index';

const baseUrl = 'users/';

export const getUser = (id = 'me') =>
  http({
    method: 'GET',
    url: `${baseUrl}${id}/`,
    headers: { ...getAuthHeader() },
  });

export const createUser = (data, apiVer = 2) => {
  let form_data = null;
  if (data.profile_image || data.header_image) {
    form_data = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      console.log({ key, value });
      if (value) {
        form_data.append(
          key,
          key === 'profile_image' || key === 'header_image'
            ? value?.[0]
            : value,
        );
      }
    });
  }

  return http({
    method: 'POST',
    url: `/users/${apiVer === 2 ? 'v2/register/' : ''}`,
    data: form_data || data,
  });
};

export const patchUser = (payload, id = 'me') => {
  let form_data = null;

  if (payload.profile_image || payload.header_image) {
    form_data = new FormData();
    Object.entries(payload).forEach(([key, value]) =>
      form_data.append(key, value),
    );

    const { profile_image, header_image } = payload;

    if (typeof profile_image === 'string') {
      form_data.delete('profile_image');
    }

    if (typeof header_image === 'string') {
      form_data.delete('header_image');
    }
  }
  return http({
    method: 'PATCH',
    url: `${baseUrl}${id}/`,
    headers: { ...getAuthHeader() },
    data: payload.profile_image || payload.header_image ? form_data : payload,
  });
};

export const deleteUser = (id = 'me') =>
  http({
    method: 'DELETE',
    url: `${baseUrl}${id}/`,
    headers: { ...getAuthHeader() },
  });

export const requestActivationLink = () =>
  http({
    method: 'POST',
    url: `${baseUrl}v2/resend_activation_email/`,
    headers: { ...getAuthHeader() },
  });

export const addHobbySkill = (payload, id = 'me') =>
  http({
    method: 'POST',
    url: `${baseUrl}${id}/hobby_skills/`,
    headers: { ...getAuthHeader() },
    data: payload,
  });

export const removeHobbySkill = (skillId, id = 'me') =>
  http({
    method: 'DELETE',
    url: `${baseUrl}${id}/hobby_skills/${skillId}/`,
    headers: { ...getAuthHeader() },
  });

export const createExperience = (payload, id = 'me') => {
  const { xpType, values: data } = payload;
  const url = `${baseUrl}${id}/`;
  const config = {
    method: 'POST',
    headers: { ...getAuthHeader() },
    data,
  };

  if (xpType === 1) {
    return http({ ...config, url: `${url}job_experiences/` });
  }
  if (xpType === 2) {
    return http({ ...config, url: `${url}education_experiences/` });
  }
  if (xpType === 3) {
    return http({ ...config, url: `${url}other_experiences/` });
  }
  return Error('Unknow xpType');
};

export const patchExperience = (payload, id = 'me') => {
  const { xpType, values } = payload;
  const { id: expId, ...data } = values;
  const url = `${baseUrl}${id}/`;
  const config = {
    method: 'PATCH',
    headers: { ...getAuthHeader() },
    data,
  };

  if (xpType === 1) {
    return http({ ...config, url: `${url}job_experiences/${expId}/` });
  }
  if (xpType === 2) {
    return http({ ...config, url: `${url}education_experiences/${expId}/` });
  }
  if (xpType === 3) {
    return http({ ...config, url: `${url}other_experiences/${expId}/` });
  }
  return Error('Unknow xpType');
};

export const delteExperience = (payload, id = 'me') => {
  const { xp_type: xpType, id: expId } = payload;

  const url = `${baseUrl}${id}/`;
  const config = {
    method: 'DELETE',
    headers: { ...getAuthHeader() },
  };

  if (xpType === 1) {
    return http({ ...config, url: `${url}job_experiences/${expId}/` });
  }
  if (xpType === 2) {
    return http({ ...config, url: `${url}education_experiences/${expId}/` });
  }
  if (xpType === 3) {
    return http({ ...config, url: `${url}other_experiences/${expId}/` });
  }
  return Error('Unknow xpType');
};

export const createUserMedia = (payload, id = 'me') => {
  const url = `${baseUrl}${id}/media/`;
  const config = {
    method: 'POST',
    headers: { ...getAuthHeader() },
    data: payload,
  };

  return http({ ...config, url });
};

export const patchUserMedia = (payload, id = 'me') => {
  const url = `${baseUrl}${id}/media/${payload.id}/`;
  const { image, video, file, ...rest } = payload;
  const config = {
    method: 'PATCH',
    headers: { ...getAuthHeader() },
    data: rest,
  };

  return http({ ...config, url });
};

export const deleteUserMedia = (mediaId, id = 'me') =>
  http({
    url: `${baseUrl}${id}/media/${mediaId}/`,
    method: 'DELETE',
    headers: { ...getAuthHeader() },
  });

export const getUserPermanentToken = (id, token) =>
  http({
    url: `userprofile/v2/permanent/${id}/?p_token=${token}`,
    method: 'GET',
  });

export const getUserTemporaryToken = (id, token) =>
  http({
    url: `userprofile/temporary/${id}/?token=${token}`,
    method: 'GET',
  });

export const requestPasswordReset = ({ email }) =>
  http({
    url: `send_password_reset_confirmation/`,
    method: 'POST',
    data: { email },
  });

export const resetPassword = ({ token }) =>
  http({
    url: `reset_password/`,
    method: 'POST',
    data: { token },
  });

export const acceptRegionsSectorTerms = ({ region_sector }) =>
  http({
    url: `${baseUrl}me/save_accepted_terms/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
    data: { region_sector },
  });

export const addReferee = payload =>
  http({
    url: `${baseUrl}me/referees/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
    data: payload,
  });

export const patchReferee = (payload, id = 'me') => {
  const { id: refereeId, ...rest } = payload;
  return http({
    url: `${baseUrl}${id}/referees/${refereeId}/`,
    method: 'PATCH',
    headers: { ...getAuthHeader() },
    data: rest,
  });
};

export const deleteReferee = (payload, id = 'me') => {
  const { id: refereeId } = payload;
  return http({
    url: `${baseUrl}${id}/referees/${refereeId}/`,
    method: 'DELETE',
    headers: { ...getAuthHeader() },
  });
};

export const activateTwoFactorAuth = (number, id = 'me') => {
  return http({
    url: `${baseUrl}${id}/add_otp_device/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
    data: {
      number,
    },
  });
};

export const verifyTwoFactorAuth = (token, id = 'me') => {
  return http({
    url: `${baseUrl}${id}/verify_otp_device/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
    data: {
      token,
    },
  });
};

export const refreshShareToken = () => {
  return http({
    url: `userprofile/v2/update/refresh_token/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
  });
};

export const changeShareTokenSharedState = () => {
  return http({
    url: `userprofile/v2/update/change_shared_state/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
  });
};

export const getUserCreatedShareToken = () => {
  return http({
    url: `userprofile/v2/temporary/update/`,
    method: 'GET',
    headers: { ...getAuthHeader() },
  });
};

export const createUserCreatedShareToken = params => {
  return http({
    url: `userprofile/v2/temporary/update/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
    params,
  });
};

export const deleteUserCreatedShareToken = () => {
  return http({
    url: `userprofile/v2/temporary/update/delete_user_created_temporary_share/`,
    method: 'POST',
    headers: { ...getAuthHeader() },
  });
};

export default {
  getUser,
  createUser,
  patchUser,
  deleteUser,
  requestActivationLink,
  createExperience,
  patchExperience,
  delteExperience,
  createUserMedia,
  patchUserMedia,
  deleteUserMedia,
  getUserTemporaryToken,
  getUserPermanentToken,
  requestPasswordReset,
  resetPassword,
  addReferee,
  patchReferee,
  deleteReferee,
  activateTwoFactorAuth,
  verifyTwoFactorAuth,
  refreshShareToken,
  changeShareTokenSharedState,
  getUserCreatedShareToken,
  deleteUserCreatedShareToken,
};
