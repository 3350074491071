import React, { Suspense, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { render } from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { FacebookProvider } from 'react-facebook';
import { CookieConsentProvider } from 'context/CookieConsentContext';
import LoadingPage from './components/LoadingPage';

import store from './store';
import { defaultTheme } from './styles/themes';
import Views from './views';
import { AppContextProvider } from './context/App';

import i18n from './services/i18n';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import { useDialog } from './components/Dialog';

// Place all global wrappers/Providers here!
function Wrappers() {
  const [userConfirmMessage, setUserConfirmMessage] = useState('');
  const userConfirmationDialog = useDialog();

  const getUserConfirmation = (message, callback) => {
    setUserConfirmMessage(message);
    userConfirmationDialog[0]
      .openDialog()
      .then(() => callback(true))
      .catch(() => callback(false))
      .finally(() => setUserConfirmMessage(''));
  };

  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={defaultTheme}>
          <StoreProvider store={store}>
            <Suspense fallback={<LoadingPage debug="Suspense Fallback" />}>
              <SnackbarProvider maxSnack={5}>
                <CookieConsentProvider>
                  <AppContextProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <FacebookProvider appId={process.env.FACEBOOK_APPID}>
                        <Views
                          userConfirmMessage={userConfirmMessage}
                          userConfirmationDialog={userConfirmationDialog}
                        />
                      </FacebookProvider>
                    </MuiPickersUtilsProvider>
                  </AppContextProvider>
                </CookieConsentProvider>
              </SnackbarProvider>
            </Suspense>
          </StoreProvider>
        </ThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

render(<Wrappers />, document.getElementById('root'));
