import vars from 'utils/_vars';
import axios from 'axios';

function makeBaseURL() {
  switch (vars.api) {
    case 'local':
      return 'http://localhost:8000/api/';
    case 'profiili':
      return 'https://server.profiili.io/api/';
    case 'profiili_io':
      return 'https://server.profiili.io/api/';
    case 'profiiliDev':
      return 'https://dev.profiili.io/api/';
    case 'appdev-varvaamo-fi':
      return 'https://dev.profiili.io/api/';
    default:
      return 'https://server.profiili.io/api/';
  }
}

const http = axios.create({
  baseURL: makeBaseURL(),
});

// Add a request interceptor
http.interceptors.request.use(
  config => {
    // Do something before request is sent

    return {
      ...config,
      headers: {
        ...config.headers,
        'Accept-Language': localStorage.getItem('i18nextLng'),
      },
    };
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
http.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const { data, status } = response;
    return { data, status };
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.debug('original error', error);
    const { data, status } = error?.response || {};

    if (error?.response?.status === 401) {
      console.log('ERRORIII');
      localStorage.removeItem('token');
      sessionStorage.clear();
      // window.location.href = "/";
    }
    return Promise.reject({ data, status });
  },
);

export const getAuthHeader = () => ({
  Authorization: `JWT ${localStorage.getItem('token')}`,
});

export default http;
