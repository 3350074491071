import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStoreState, useStoreActions } from 'easy-peasy';
// import { useTranslation } from 'react-i18next';
import { setSectorTheme, setSectorDarkTheme } from 'styles/themes';
import { getIndustries } from 'services/http/industries';
import LoadingPage from 'components/LoadingPage';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import i18n from 'services/i18n';
// import withSector from 'views/withSector'

import useSocket from 'services/socket';
import { getIn } from 'formik';

const AppContext = createContext({});
AppContext.displayName = 'AppContext';

const AppContextProviderComponent = props => {
  const theme = useTheme();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [namedLoading, setNamedLoading] = useState([]);
  const [sideMenu, setSideMenu] = useState(false);
  const [profileScore, setProfileScore] = useState(true);
  const [industries, setIndustries] = useState([]);
  const { loggedIn, data: userData, token, forceUserLoad } = useStoreState(
    s => s.user,
  );
  const {
    getUser,
    logout,
    getUserNotifications,
    getUserNotificationsList,
    getMyUserLightEntrepreneurProfile,
    setMyUserLightEntrepreneurProfileLoading,
  } = useStoreActions(a => a.user);
  const { getAllChats } = useStoreActions(a => a.chat);
  const { getSectorTiitus } = useStoreActions(a => a.sector);
  const sector = useStoreState(s => s.sector.data);
  const openSideMenu = useCallback(() => setSideMenu(true), [setSideMenu]);
  const closeSideMenu = useCallback(() => setSideMenu(false), [setSideMenu]);
  const openProfileScore = useCallback(() => setProfileScore(true), [
    setProfileScore,
  ]);
  const closeProfileScore = useCallback(() => setProfileScore(false), [
    setProfileScore,
  ]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isTiitus, setIsTiitus] = useState(-1);

  const [sectorIdentifier, setSectorIdentifier] = useState('');
  const socket = useSocket({ token });
  const setLoader = useCallback(key => {
    setNamedLoading(state => [...state, key]);
    return () => setNamedLoading(state => state.filter(k => k !== key));
  });

  const handleGetIndustries = () => {
    getIndustries().then(res => setIndustries(res.data));
  };

  useEffect(() => {
    handleGetIndustries();
    getAllChats();
    getSectorTiitus({ identifiers: 20 }); // 20=värväämö, 1003=profiili
    setIsTiitus(0);
  }, []);

  /*
  useEffect(() => {
    console.log('AppContextProvider match', match)
  }, [match])
  */

  /*
  useEffect(() => {
    if (sectorIdentifier === '') {
      setLoading(false);
    }
    if (
      (!sector || sector.name_slug !== sectorIdentifier) &&
      sectorIdentifier !== '' &&
      !loggedIn
    ) {
      clearSector();
      setLoading(true);
      console.log('sectorIdentifier', { sectorIdentifier });
      if (sectorIdentifier) {
        getSector({ name: sectorIdentifier }).then(res => {
          if (!res?.payload?.id) {
            getSector({ identifiers: 1003 });
          }
        });
      } else {
        getSector({
          identifiers: window.location.hostname === 'localhost' || window.location.hostname === 'app.tiitus.fi' || window.location.hostname === 'appdev.tiitus.fi' ? 1 : 1003,
        });
      }
    }
  }, [sectorIdentifier]);
  */

  useEffect(() => {
    if (!isMobile) closeSideMenu();
  }, [isMobile]);

  useEffect(() => {
    if (loggedIn && !userData?.id && !forceUserLoad) {
      setLoading(true);
      getUser()
        .then(res => {
          console.debug('getUser res', res);
          getUserNotifications();
          getUserNotificationsList();
          if (res?.payload?.light_entrepreneur_profile) {
            setMyUserLightEntrepreneurProfileLoading();
            getMyUserLightEntrepreneurProfile();
          }
          if (
            res?.payload.language === 'fi' ||
            res?.payload.language === 'en'
          ) {
            if (!(i18n.language === res?.payload.language)) {
              i18n.changeLanguage(res?.payload.language);
            }
          }
          /*
          if (window.location.hostname !== 'app.tiitus.fi') {
            if (res.data.region_workspace !== sector?.id || !sector) {
              getSector({ ids: userData?.region_workspace })
                .catch(error => console.error(error))
                .finally(() => {});
            }
          }
          */
        })
        .catch(error => {
          console.log(error);
          if (error?.data?.code === 'authentication_failed') {
            enqueueSnackbar(t('Istunto on vanhentunut'), { variant: 'error' });
            logout();
          }
        });
    }
  }, [loggedIn, userData?.id]);

  /*
  useEffect(() => {
    if (
      loggedIn &&
      userData &&
      (!sector || sector?.id !== userData?.region_workspace)
    ) {
      setLoading(true);
      getSector({ ids: userData?.region_workspace }).catch(error =>
        console.error(error),
      );
    }
  }, [userData?.region_workspace, sector?.id]);
  */

  useEffect(() => {
    setLoading(false);
    if (!loggedIn) {
      if (sector?.id && Boolean(Object.keys(industries).length)) {
        setLoading(false);
      }
    } else if (loggedIn) {
      if (
        // sector?.id === userData?.region_workspace &&
        Boolean(Object.keys(industries).length) &&
        userData?.id &&
        sector?.id
      ) {
        setLoading(false);
      }
    }
  }, [sector?.id, loggedIn, industries, userData?.id, loading]);

  useEffect(() => {
    /*
    if (sector?.name) {
      document.title = sector?.name;
    }
    */
  }, [sector?.name]);

  return loading || namedLoading.length ? (
    <LoadingPage debug="AppContext" />
  ) : (
    <AppContext.Provider
      value={{
        socket,
        isMobile,
        isTiitus,
        isSideMenuOpen: sideMenu,
        openSideMenu,
        closeSideMenu,
        isProfileScoreOpen: profileScore,
        industries,
        openProfileScore,
        closeProfileScore,
        sectorTheme: setSectorTheme(sector),
        sectorDarkTheme: setSectorDarkTheme(sector),
        setLoading,
        setLoader,
        sectorIdentifier,
        setSectorIdentifier,
        handleGetIndustries,
      }}
      {...props}
    />
  );
};

/*
AppContextProviderComponent.defaultProps = {

}
*/

AppContextProviderComponent.propTypes = {
  children: PropTypes.element.isRequired,
};

// export const AppContextProvider = withSector(AppContextProviderComponent)
export const AppContextProvider = AppContextProviderComponent;

export default AppContext;

export const useAppContext = () => useContext(AppContext);
