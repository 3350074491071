import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';

import LoadingPage from 'components/LoadingPage';

function PrivateRoute({ component: Component, noRedirect, ...rest }) {
  const { loggedIn, hasUserData, data } = useStoreState(s => s.user);
  const location = useLocation();

  const { getSector } = useStoreActions(a => a.sector);

  useEffect(() => {
    if (data?.region_workspace) {
      getSector({ ids: data?.region_workspace });
    }
  }, [data?.region_workspace]);

  return (
    <Route
      {...rest}
      render={props =>
        // eslint-disable-next-line no-nested-ternary
        loggedIn ? (
          hasUserData ? (
            <Component {...props} />
          ) : (
            <LoadingPage debug="PrivateRoute" />
          )
        ) : (
          !noRedirect && (
            <Redirect
              to={{
                pathname: location.pathname === '/' ? '/intro' : '/login',
                state: { redirectFrom: { ...location } },
              }}
            />
          )
        )
      }
    />
  );
}

PrivateRoute.defaultProps = {
  noRedirect: false,
};

PrivateRoute.propTypes = {
  noRedirect: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
};
export default PrivateRoute;
