import http from './index';

const defaultProps = { params: {} };
const baseUrl = 'enums/';

export const getEnums = ({ params } = defaultProps) =>
  http({ url: baseUrl, method: 'GET', params });

export default {
  getEnums,
};
