import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as DomLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

export const RouterLink = React.forwardRef((props, ref) => (
  <DomLink innerRef={ref} {...props} />
));

RouterLink.displayName = 'RouterLink';

function Link({ isExternal, ...rest }) {
  const getLinkProps = useCallback(
    () =>
      isExternal
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
            component: 'a',
          }
        : { component: RouterLink },
    [isExternal],
  );

  return <MuiLink color="secondary" {...getLinkProps()} {...rest} />;
}

Link.defaultProps = {
  isExternal: false,
  to: undefined,
  href: undefined,
  color: 'secondary',
};

Link.propTypes = {
  isExternal: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.string,
};

export default Link;
