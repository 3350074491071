/* eslint-disable prefer-destructuring */
export const debug = Boolean(process.env.DEBUG === 'true');
export const NODE_ENV = process.env.NODE_ENV;
export const SERV_ENV = process.env.SERV_ENV;
export const API = process.env.API;
export const settings = {};

console.log({ debug, NODE_ENV, SERV_ENV, API });

export default {
  api: process.env.API,
  env: process.env.NODE_ENV,
  debug,
  settings,
};
