import React, { createContext, useContext, useEffect, useState } from 'react';

const CookieConsentContext = createContext();

export const CookieConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(() =>
    localStorage.getItem('cookie_consent'),
  );

  // Start or block Google Tag Manager
  const handleTagManager = status => {
    const gtmScript = document.getElementById('gtm-script');
    if (status === 'accepted') {
      if (!gtmScript) {
        const script = document.createElement('script');
        script.id = 'gtm-script';
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-NT7JNCLC';
        document.head.appendChild(script);
      }
    } else {
      if (gtmScript) {
        gtmScript.remove();
      }
      window.dataLayer = []; // Cleanin data layer
    }
  };

  // Updates cookie settings in localStorage and manages the loading of Google Tag Manager
  useEffect(() => {
    if (consent) {
      localStorage.setItem('cookie_consent', consent);
      handleTagManager(consent);
    }
  }, [consent]);

  // Accept cookies
  const acceptCookies = () => setConsent('accepted');

  // Denied cookies
  const denyCookies = () => setConsent('denied');

  return (
    <CookieConsentContext.Provider
      value={{ consent, acceptCookies, denyCookies }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => useContext(CookieConsentContext);
