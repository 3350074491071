const htmlFontSize = 16;
const fontSize = 14;

export const pxToRem = px => `${px / htmlFontSize}rem`;

export default {
  useNextVariants: true,
  htmlFontSize,
  fontSize,
  h1: {
    fontSize: pxToRem(fontSize * 4),
  },
  h2: {
    fontSize: pxToRem(fontSize * 3),
  },
  h3: {
    fontSize: pxToRem(fontSize * 2.5),
  },
  h4: {
    fontSize: pxToRem(fontSize * 2),
  },
  h5: {
    fontSize: pxToRem(fontSize * 1.5),
  },
  h6: {
    fontSize: pxToRem(fontSize * 1.25),
  },
  body1: {
    fontSize: pxToRem(fontSize),
  },
  subtitle1: {
    fontWeight: 500,
  },
};
