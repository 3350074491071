import http, { getAuthHeader } from './index';

const baseUrl = '/regions/regions_sectors/';

export const getSector = params =>
  http({
    method: 'GET',
    url: baseUrl,
    params,
    /*
    url: identifier?.params
      ? baseUrl
      : `${baseUrl}${
          Number.isNaN(Number.parseInt(identifier, 10)) ? '?name=' : '?ids='
        }${identifier}`,
    params: identifier?.params ? identifier.params : null,
    */
  });

export const getAllVisibleSectors = () =>
  http({
    method: 'GET',
    url: `${baseUrl}?visible_user=true`,
  });

export default {
  getSector,
};

export const verifySectorLicenseKey = (id, license_key) =>
  http({
    method: 'GET',
    url: `/regions/check_academy`,
    params: { id, license_key },
    headers: { ...getAuthHeader() },
  });
