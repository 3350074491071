import http, { getAuthHeader } from './index';

export const httpGetMyNotifications = () => {
  return http({
    method: 'GET',
    url: '/notifications/my_notifications/',
    headers: { ...getAuthHeader() },
  });
};

export const httpGetMyNotificationsList = () => {
  return http({
    method: 'GET',
    url: '/notifications/my_notifications_list/',
    headers: { ...getAuthHeader() },
  });
};

export const httpReadNotification = id => {
  return http({
    method: 'POST',
    url: `/notifications/my_notifications_list/read/${id}/`,
    headers: { ...getAuthHeader() },
  });
};

export default {
  httpGetMyNotifications,
  httpGetMyNotificationsList,
  httpReadNotification,
};
