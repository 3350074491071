import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import loadingBackgroundImage from 'assets/defaultBg.jpg';

// <editor-fold desc="Component style definition ...">
const useStyles = makeStyles(theme => {
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 100,
      color: '#fff',
    },
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: '50% 50%',
      filter: 'blur(8px)',
      zIndex: theme.zIndex.drawer + 10,
    },
  };
});
// </editor-fold>

// <editor-fold desc="Component type definitions ...">

export type ComponentProps = {
  loadingText?: string;
  open: boolean;
  fullPageLoader?: boolean;
  disableSpinner?: boolean;
};
// </editor-fold>

// <editor-fold desc="Component component definition ...">
const Component = (props: ComponentProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loadingText = t('Ladataan'),
    open,
    fullPageLoader = false,
    disableSpinner = false,
  } = props;

  return (
    <Fragment>
      {fullPageLoader ? (
        <img
          src={loadingBackgroundImage}
          alt="pg"
          className={classes.background}
        />
      ) : null}
      <Backdrop className={classes.backdrop} open={open}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography align="center" variant="h4" color="inherit">
              {`${loadingText}...`}
            </Typography>
          </Grid>
          {disableSpinner ? null : (
            <Grid item>
              <CircularProgress
                color="inherit"
                size={80}
                style={{ margin: '0 auto', display: 'block' }}
              />
            </Grid>
          )}
        </Grid>
      </Backdrop>
    </Fragment>
  );
};
// </editor-fold>

export default Component;
