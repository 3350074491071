import http, { getAuthHeader } from './index';

const baseUrl = '/application/my/';

const defaultPagination = { offset: 0, limit: 5, ordering: '-time_received' };

export const getMyApplications = ({ params } = {}) =>
  http({
    method: 'GET',
    url: '/my_applications/',
    params: { ...defaultPagination, ...params },
    headers: { ...getAuthHeader() },
  });

export const getApplication = payload =>
  http({
    method: 'GET',
    url: `${baseUrl}${payload.id}/`,
    headers: { ...getAuthHeader() },
  });

export const sendApplication = ({ data, params }) =>
  http({
    method: 'POST',
    url: '/application/applicant/',
    headers: { ...getAuthHeader() },
    params,
    data,
  });

export default {
  getMyApplications,
  getApplication,
  sendApplication,
};
