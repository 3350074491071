import React, { useState, useCallback } from 'react';
// import PropTypes from 'prop-types';

import Dialog from './Dialog';

const dialogPromiseDefaultState = {
  resolve: null,
  reject: null,
};

const useDialogDefaultProps = {
  onAccept: Promise.resolve.bind(Promise), // Promise.resolve and Promise.reject
  onReject: Promise.reject.bind(Promise), // refers to the resolve/reject function without context object.
  // so creating a bound versions of  them.
};

export function useDialog(props = useDialogDefaultProps) {
  const [dialogPromise, setDialogPromise] = useState(dialogPromiseDefaultState);

  const openDialog = () =>
    new Promise((res, rej) => setDialogPromise({ resolve: res, reject: rej }))
      .then(props.onAccept)
      .catch(props.onReject)
      .finally(() => setDialogPromise(dialogPromiseDefaultState));

  const resolve = useCallback(
    val => dialogPromise.resolve !== null && dialogPromise.resolve(val),
    [dialogPromise.resolve],
  );

  const reject = useCallback(
    val => dialogPromise.reject !== null && dialogPromise.reject(val),
    [dialogPromise.reject],
  );

  return [
    {
      openDialog,
      resolve,
      reject,
    },
    {
      open: Boolean(dialogPromise.resolve && dialogPromise.reject),
      onClose: dialogPromise.reject,
    },
  ];
}

function DialogContainer(props) {
  return <Dialog {...props} />;
}

export default DialogContainer;
