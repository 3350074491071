import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cl from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Badge } from '@material-ui/core';
import { Face } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  avatar: ({ size, noShadows }) => ({
    width: size,
    height: size,
    boxShadow: noShadows ? 'none' : theme.shadows[5],
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[600],
  }),
  avatarIcon: {
    width: 'inherit',
    height: 'inherit',
  },
  badge: {
    backgroundColor: '#44b700',
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid #44b700',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function AvatarContainer(props) {
  const { size, className, active, children, noShadows, ...rest } = props;
  const classes = useStyles({ size, noShadows });

  const makeAvatarChild = useCallback(() => {
    return children ? (
      children?.slice(0, 2).toUpperCase()
    ) : (
      <Face className={classes.avatarIcon} />
    );
  }, [children, classes.avatarIcon]);

  return (
    <Badge
      classes={{ badge: classes.badge }}
      overlap="circle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      variant="dot"
      invisible={!active}
    >
      <Avatar className={cl(classes.avatar, className)} {...rest}>
        {makeAvatarChild()}
      </Avatar>
    </Badge>
  );
}

AvatarContainer.defaultProps = {
  size: 36,
  className: '',
  active: false,
  children: '',
  noShadows: false,
};

AvatarContainer.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.string,
  noShadows: PropTypes.bool,
};

export default AvatarContainer;
