/* theme palette more info at: https://material-ui.com/system/palette/ */

export default {
  primary: {
    main: '#434446',
  },
  secondary: {
    main: '#F24E5C',
  },
  green: {
    main: '#4caf50',
  },
  // text: {
  //   primary: 'rgba(0,0,0,0.9)',
  //   secondary: 'rgba(0,0,0,75)',
  // },
  // error: {
  //   main: '#f44336',
  // },
  experience: id => {
    switch (id) {
      case 1:
        return '#7758A0';
      case 2:
        return '#EB6250';
      case 3:
        return '#2d9c81';
      default:
        throw Error(
          `Unknow experience color key ${id}!\nUse 1 for job, 2 for education, 3 for others`,
        );
    }
  },
};
