import http, { getAuthHeader } from './index';

const baseUrl = '/application/open_application/';

const defaultPagination = { offset: 0, limit: 5, ordering: '-time_received' };

export const getMyOpenApplications = ({ params } = {}) => {
  console.log({ ...defaultPagination, ...params });
  return http({
    method: 'GET',
    url: baseUrl,
    params: { ...defaultPagination, ...params },
    headers: { ...getAuthHeader() },
  });
};

export const getOpenApplication = payload =>
  http({
    method: 'GET',
    url: `/application/open_application/${payload.id}/`,
    headers: { ...getAuthHeader() },
  });

export const getUserOpenApplications = () =>
  http({
    method: 'GET',
    url: `${baseUrl}`,
    headers: { ...getAuthHeader() },
  });

export const sendOpenApplication = ({ data }) =>
  http({
    method: 'POST',
    url: `${baseUrl}`,
    data: { ...data },
    headers: { ...getAuthHeader() },
  });

export default {
  getMyOpenApplications,
  getOpenApplication,
};
