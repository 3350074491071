import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import defaultBackgroundImage from 'assets/defaultBg.jpg';

const useStyles = makeStyles(theme => {
  const smUp = theme.breakpoints.up('sm');
  const xsUp = theme.breakpoints.up('xs');

  return {
    img: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      objectFit: 'cover',
      overflow: 'hidden',
      // objectPosition: '50% 50%',
      zIndex: -1,
      /*
      [`${xsUp} and (orientation: landscape)`]: {
        paddingTop:
          theme.mixins.toolbar[`${xsUp} and (orientation: landscape)`]
            .minHeight,
      },
      [smUp]: {
        paddingTop: theme.mixins.toolbar[smUp].minHeight,
      },
      paddingTop: theme.mixins.toolbar.minHeight,
       */
    },
  };
});

function BackgroundImage({ image }) {
  const classes = useStyles();
  return (
    <img
      className={classes.img}
      src={image || defaultBackgroundImage}
      alt="fullscreen background"
    />
  );
}

BackgroundImage.defaultProps = {
  image: null,
};

BackgroundImage.propTypes = {
  image: PropTypes.string,
};

export default BackgroundImage;
