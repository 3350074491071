import http, { getAuthHeader } from './index';

const baseUrl = '/meeting_requests/';

const defaultPagination = { offset: 0, limit: 5, ordering: '-time_received' };

export const getMyMeetingRequests = ({ params } = {}) =>
  http({
    method: 'GET',
    params: { ...defaultPagination, ...params },
    url: baseUrl,
    headers: { ...getAuthHeader() },
  }).then(res => {
    console.log('getMyMeetingRequests', { res });
    return res;
  });

export const getMyMeetingRequest = id =>
  http({
    method: 'GET',
    url: `${baseUrl}${id}`,
    headers: { ...getAuthHeader() },
  });

export const setMeetingRequestOpened = id =>
  http({
    method: 'get',
    url: `${baseUrl}${id}/set_meeting_request_opened/`,
    headers: { ...getAuthHeader() },
  });

export const acceptMeetingRequest = id =>
  http({
    method: 'POST',
    url: `${baseUrl}${id}/accept/`,
    headers: { ...getAuthHeader() },
  });

export const rejectMeetingRequest = id =>
  http({
    method: 'DELETE',
    url: `${baseUrl}${id}`,
    headers: { ...getAuthHeader() },
  });

export default {
  getMyMeetingRequests,
  getMyMeetingRequest,
  acceptMeetingRequest,
  rejectMeetingRequest,
};
