import { createStore } from 'easy-peasy';
import { createLogger } from 'redux-logger';
import authServices from 'services/http/token';
import sectorService from 'services/http/sector';
import userService from 'services/http/user';
import academyService from 'services/http/academy';
import enumService from 'services/http/enum';
import applicationsService from 'services/http/applications';
import openApplicationsService from 'services/http/openApplications';
import meetingRequestsService from 'services/http/meetingRequests';
import chatService from 'services/http/chat';
import jobWatcherService from 'services/http/jobWatcher';
import notificationService from 'services/http/notifications';
import lightEntrepreneurService from 'services/http/lightEntrepreneur';
import user from './user';
import sector from './sector';
import enums from './enums';
import academy from './academy';
import applications from './applications';
import chat from './chat';
import meetingRequest from './meetingRequest';
import jobWatcher from './jobWatcher';

const logger = createLogger({
  collapsed: true,
});

const store = createStore(
  {
    user,
    enums,
    sector,
    academy,
    applications,
    chat,
    meetingRequest,
    jobWatcher,
  },
  {
    injections: {
      authServices,
      userService,
      enumService,
      sectorService,
      academyService,
      applicationService: {
        applications: applicationsService,
        openApplications: openApplicationsService,
      },
      chatService,
      meetingRequestsService,
      jobWatcherService,
      notificationService,
      lightEntrepreneurService,
    },
  },
  { middleware: [logger] },
);

store.getActions().enums.getEnums();

export default store;
