/* MuiGrid overrides */
export default {
  item: {
    maxWidth: '100%',
    display: 'inline-block',
  },
  'spacing-xs-1': {
    width: 'calc(100% + 9px)',
  },
  'spacing-xs-2': {
    width: 'calc(100% + 17px)',
  },
  'spacing-xs-3': {
    width: 'calc(100% + 25px)',
  },
  'spacing-xs-4': {
    width: 'calc(100% + 33px)',
  },
  'spacing-xs-5': {
    width: 'calc(100% + 41px)',
  },
  'spacing-xs-6': {
    width: 'calc(100% + 49px)',
  },
  'spacing-xs-7': {
    width: 'calc(100% + 57px)',
  },
  'spacing-xs-8': {
    width: 'calc(100% + 65px)',
  },
  'spacing-xs-9': {
    width: 'calc(100% + 73px)',
  },
  'spacing-xs-10': {
    width: 'calc(100% + 81px)',
  },
};
