/* eslint-disable no-param-reassign */
import { action, thunk, persist, computed } from 'easy-peasy';

// type: 1 application 2 open appilication 3 meeting request

const initialState = {
  chats: {},
  open: '',
};

export default persist({
  ...initialState,
  setOpenChat: action((state, payload) => {
    state.open = payload;
  }),
  hasNewMessages: computed(state => userId =>
    userId
      ? Object.entries(state.chats).reduce(
          (acc, [key, messages]) =>
            messages.find(m => !m.sender_me && !m.read.includes(userId))
              ? [...acc, key]
              : acc,
          [],
        )
      : [],
  ),
  lastMessageId: action((state, payload) => {
    const { type, id } = payload;
    const key = `${type}_${id}`;
    return state?.chats?.[key]?.[0].id;
  }),
  setChat: action((state, payload) => {
    const { messages, params } = payload;
    const key = payload.key || `${params.object_type}_${params.object_id}`;
    state.chats[key] = messages;
  }),
  getChat: thunk((actions, payload, { injections }) =>
    injections.chatService.getChatMessages(payload).then(
      ({ data }) => actions.setChat({ messages: data, ...payload }),
      errors => {
        console.log('getChat.errors', errors);
      },
    ),
  ),
  getAllChats: thunk((actions, payload, { injections }) =>
    injections.chatService
      .getChatMessages({ params: { no_read_mark: true } })
      .then(
        ({ data }) => {
          const chats = data.reduce((acc, cur) => {
            const key = `${cur.object_type}_${cur.object_id}`;
            if (acc[key]) {
              return { ...acc, [key]: [...acc[key], cur] };
            }
            return { ...acc, [key]: [cur] };
          }, {});

          Object.entries(chats).forEach(([key, messages]) =>
            actions.setChat({ messages, key }),
          );
        },
        errors => {
          console.log('getAllChats.errors', errors);
        },
      ),
  ),
  appendNewChatMessages: action((state, payload) => {
    const { messages, params } = payload;
    const key = `${params.object_type}_${params.object_id}`;
    state.chats[key] = [...messages, ...state.chats[key]];
  }),
  getNewChatMessages: thunk((actions, payload, { injections }) =>
    injections.chatService
      .getChatMessages(payload)
      .then(({ data }) =>
        actions.appendNewChatMessages({ messages: data, ...payload }),
      ),
  ),
  appendNewMessage: action((state, payload) => {
    const { message, data } = payload;
    const key = `${data.application_type}_${data.application_id}`;
    state.chats[key] = [message, ...state.chats[key]];
  }),
  sendMessage: thunk((actions, payload, { injections }) =>
    injections.chatService
      .newChatMessage(payload)
      .then(({ data }) =>
        actions.appendNewMessage({ message: data, ...payload }),
      ),
  ),
});
