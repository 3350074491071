import React from 'react';
import { Switch, Route } from 'react-router-dom';

const withSector = BaseComponent => props => {
  return (
    <Switch>
      <Route path="/:sector?/" render={() => <BaseComponent {...props} />} />
    </Switch>
  );
};

export default withSector;
