/* eslint-disable no-param-reassign */
import { computed, action, thunk } from 'easy-peasy';

const initialState = {
  data: null,
  error: null,
  tiitus: null,
};

export default {
  ...initialState,
  hasSectorData: computed(state => Boolean(state.data?.id)),
  setSectorData: action((state, payload) => {
    state.data = payload;
    state.error = null;
  }),
  clearSector: action(state => {
    state.data = null;
  }),
  setSectorError: action((state, payload) => {
    state.error = payload;
  }),
  setSectorTiitusData: action((state, payload) => {
    state.tiitus = payload;
  }),
  getSector: thunk((actions, payload, { injections }) =>
    injections.sectorService
      .getSector(payload)
      .then(({ data }) => {
        if (data && data.length > 0) {
          return actions.setSectorData(data[0]);
        }
        return null;
      })
      .catch(error => actions.setSectorError(error)),
  ),
  getSectorTiitus: thunk((actions, payload, { injections }) =>
    injections.sectorService.getSector(payload).then(({ data }) => {
      if (data && data.length > 0) {
        return actions.setSectorTiitusData(data[0]);
      }
      return null;
    }),
  ),
};
