import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiGrid from './MuiGrid';
import MuiFormControl from './MuiFormControl';

export default {
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiGrid,
  MuiFormControl,
  MuiFormLabel: {
    root: {
      fontSize: '1.1rem',
      lineHeight: 1,
    },
  },
  MuiInputLabel: {
    outlined: {
      '&$shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    shrink: {},
  },
  MuiTypography: {
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
  },
};
