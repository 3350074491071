import React, { useState } from 'react';
import cl from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog as MuiDialog,
  DialogTitle,
  Fab,
  Tooltip,
  ClickAwayListener,
  Typography,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  title: {
    padding: [[64, 64, 24, 64]],
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
  },
  closeFab: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  infoFab: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
}));

function Dialog(props) {
  // props
  const { title, titleProps, children, info, ...rest } = props;
  // hooks
  const classes = useStyles();

  const [isInfoOpen, setInfo] = useState(false);

  const handleInfoOpen = () => setInfo(true);
  const handleInfoClose = () => setInfo(false);

  return (
    <MuiDialog {...rest}>
      <DialogTitle
        {...titleProps}
        className={cl(classes.title, titleProps?.className)}
      >
        {title}
      </DialogTitle>
      {info && (
        <ClickAwayListener onClickAway={handleInfoClose}>
          <Tooltip
            open={isInfoOpen}
            onClose={handleInfoClose}
            arrow
            placement="bottom-start"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={info}
          >
            <Fab
              size="small"
              aria-label="Info"
              onClick={handleInfoOpen}
              className={classes.infoFab}
            >
              <Typography variant="h5">?</Typography>
            </Fab>
          </Tooltip>
        </ClickAwayListener>
      )}
      {rest.disableBackdropClick && rest.disableEscapeKeyDown ? null : (
        <Fab
          size="small"
          aria-label="Close"
          onClick={rest.onClose}
          className={classes.closeFab}
        >
          <Close />
        </Fab>
      )}
      {children}
    </MuiDialog>
  );
}

Dialog.defaultProps = {
  info: null,
  titleProps: {
    disableTypography: false,
    classes: {},
    className: '',
  },
};

Dialog.propTypes = {
  info: PropTypes.node,
  title: PropTypes.node.isRequired,
  titleProps: PropTypes.shape({
    disableTypography: PropTypes.bool,
    classes: PropTypes.object,
    className: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Dialog;
