/* eslint-disable no-param-reassign */
import { action, thunk, computed } from 'easy-peasy';

const initialState = {
  applications: {
    count: 0,
    next: '',
    previous: 'null',
    results: [],
  },
  openApplications: {
    count: 0,
    next: '',
    previous: 'null',
    results: [],
  },
};

export default {
  ...initialState,
  hasMoreApplications: computed(s => {
    const { count, results } = s.applications;
    return count > results.length;
  }),
  hasMoreOpenApplications: computed(s => {
    const { count, results } = s.openApplications;
    return count > results.length;
  }),
  setApplications: action((state, payload) => {
    state.applications = payload;
  }),

  appendNextApplicationsPage: action((state, payload) => {
    state.applications = {
      ...payload,
      results: state.applications.results.concat(payload.results),
    };
  }),

  appendNextOpenApplicationsPage: action((state, payload) => {
    state.openApplications = {
      ...payload,
      results: state.openApplications.results.concat(payload.results),
    };
  }),

  setApplication: action((state, payload) => {
    if (payload.job) {
      state.applications.results = state.applications.results.map(a =>
        a.id === payload.id ? { ...a, ...payload } : a,
      );
    } else {
      state.openApplications.results = state.openApplications.results.map(a =>
        a.id === payload.id ? { ...a, ...payload } : a,
      );
    }
  }),

  getApplications: thunk((actions, payload, { injections }) =>
    injections.applicationService.applications
      .getMyApplications(payload)
      .then(({ data }) => actions.setApplications(data)),
  ),

  getNextApplicationsPage: thunk(
    (actions, { page, rowsPerPage }, { injections, getState }) => {
      const { hasMoreApplications, applications } = getState();
      const offset = (page + 1) * rowsPerPage;
      if (applications.results.length <= offset && hasMoreApplications) {
        return injections.applicationService.applications
          .getMyApplications({
            params: { offset, limit: rowsPerPage },
          })
          .then(({ data }) => actions.appendNextApplicationsPage(data));
      }
      return Promise.resolve();
    },
  ),

  getNextOpenApplicationsPage: thunk(
    (actions, { page, rowsPerPage }, { injections, getState }) => {
      const { hasMoreOpenApplications, openApplications } = getState();
      const offset = (page + 1) * rowsPerPage;
      if (
        openApplications.results.length <= offset &&
        hasMoreOpenApplications
      ) {
        return injections.applicationService.openApplications
          .getMyOpenApplications({
            params: { offset, limit: rowsPerPage },
          })
          .then(({ data }) => actions.appendNextOpenApplicationsPage(data));
      }
      return Promise.resolve();
    },
  ),
  markApplicationMessagesRead: thunk((actions, payload, { injections }) =>
    payload.job
      ? injections.applicationService.applications
          .getApplication(payload)
          .then(({ data }) => actions.setApplication(data))
      : injections.applicationService.openApplications
          .getOpenApplication(payload)
          .then(({ data }) => actions.setApplication(data)),
  ),
  markApplicationChatRead: action((state, payload) => {
    const { type, id } = payload;
    switch (type) {
      case 1:
        state.applications.results.map(a =>
          a.id === id && !a.is_open_application
            ? (a.new_chat_messages_count = 0)
            : a,
        );
        break;
      case 2:
        state.applications.results.map(a =>
          a.id === id && a.is_open_application
            ? (a.new_chat_messages_count = 0)
            : a,
        );
        break;
      default:
        break;
    }
  }),
  markApplicationChatUnread: action((state, payload) => {
    const { type, id } = payload;
    switch (type) {
      case 1:
        state.applications.results.map(a =>
          a.id === id ? (a.new_chat_messages_count += 1) : a,
        );
        break;
      case 2:
        state.openApplications.results.map(a =>
          a.id === id ? (a.new_chat_messages_count += 1) : a,
        );
        break;
      default:
        break;
    }
    console.log('markApplicationChatRead', payload);
  }),
  setOpenApplications: action((state, payload) => {
    state.openApplications = payload;
  }),
  getOpenApplications: thunk((actions, payload, { injections }) =>
    injections.applicationService.openApplications
      .getMyOpenApplications(payload)
      .then(({ data }) => actions.setOpenApplications(data)),
  ),

  sendJobApplication: thunk((actions, payload, { injections }) =>
    payload.data.open_job_application
      ? injections.applicationService.openApplications.sendApplication(payload)
      : injections.applicationService.applications.sendApplication(payload),
  ),
};
