import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  '@global': {
    /* Scrollbar width */
    '::-webkit-scrollbar': {
      width: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    /* Scrollbar Track */
    '::-webkit-scrollbar-track': {
      background: theme.palette.grey[200],
    },

    /* Scrollbar Handle */
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
    },

    /* Scrollbar Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));
