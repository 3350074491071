import React from 'react';
import cl from 'clsx';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

function isCustomColorVariant(color) {
  switch (color) {
    case 'error':
    case 'warning':
    case 'info':
    case 'success':
      return true;
    default:
      return false;
  }
}

function customColorVariant(color, variant, theme) {
  if (isCustomColorVariant(color)) {
    switch (variant) {
      case 'text':
        return {
          color: theme.palette[color].main,
          '&:hover': {
            backgroundColor: fade(
              theme.palette[color].main,
              theme.palette.action.hoverOpacity,
            ),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        };
      case 'outlined':
        return {
          color: theme.palette[color].main,
          border: `1px solid ${fade(theme.palette[color].main, 0.5)}`,
          '&:hover': {
            border: `1px solid ${theme.palette[color].main}`,
            backgroundColor: fade(
              theme.palette[color].main,
              theme.palette.action.hoverOpacity,
            ),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        };

      default:
        return {
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
          '&:hover': {
            backgroundColor: theme.palette[color].dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: theme.palette[color].main,
            },
          },
        };
    }
  }
  return {};
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 18,
  },
  /* Styles applied to the root element if `size="small"`. */
  sizeSmall: {
    borderRadius: 15,
  },
  /* Styles applied to the root element if `size="large"`. */
  sizeLarge: {
    borderRadius: 21,
  },
  customColors: ({ color, variant }) =>
    customColorVariant(color, variant, theme),
}));

function Button({ color, className, ...rest }) {
  const { customColors, ...restOfClasses } = useStyles({ color, ...rest });

  return (
    <MuiButton
      className={cl(className, { [customColors]: isCustomColorVariant(color) })}
      classes={restOfClasses}
      color={isCustomColorVariant(color) ? undefined : color}
      {...rest}
    />
  );
}

export const defaultProps = {
  variant: 'contained',
  color: 'default',
  size: 'medium',
  className: '',
};

Button.defaultProps = defaultProps;

export const propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'error',
    'warning',
    'info',
    'success',
  ]),
};
Button.propTypes = propTypes;

export default Button;
