/* eslint-disable no-param-reassign */
import { computed, action, thunk } from 'easy-peasy';

const initialState = {
  data: null,
  token: localStorage.getItem('token') || null,
  error: null,
  forceUserLoad: false,
  notifications: [],
  notificationsList: [],
  lightEntrepreneurProfile: { loading: false, data: {}, error: false },
};

export default {
  ...initialState,
  loggedIn: computed(state => Boolean(state.token)),
  hasUserData: computed(state => Boolean(state.data?.id)),
  portfolioMedia: computed(s =>
    s?.data?.media?.filter(({ media_type }) => media_type !== 4),
  ),

  hasBasicData: computed(({ data }) =>
    Boolean(
      data?.first_name &&
        data?.last_name &&
        data?.email &&
        data?.phone &&
        data?.gender &&
        data?.birthdate &&
        data?.city,
    ),
  ),

  cvMedia: computed(s =>
    s?.data?.media?.filter(({ media_type }) => media_type === 4),
  ),
  hasSkills: computed(
    state =>
      state.data?.job_skills?.length !== 0 &&
      state.data?.language_skills?.length !== 0,
  ),
  hasExperience: computed(
    state =>
      state.data?.job_experiences?.length !== 0 ||
      state.data?.education_experiences?.length !== 0 ||
      state.data?.other_experiences?.length !== 0 ||
      state.data?.experiences_timeline?.length !== 0,
  ),
  hasMotivation: computed(
    state =>
      Boolean(state.data?.dream_job_description) ||
      Boolean(state.data?.motivation) ||
      Boolean(state.data?.character) ||
      state.data?.working_hours?.length,
  ),
  hasPortfolio: computed(state => state.data?.media?.length !== 0),
  hasProfileImage: computed(state => Boolean(state.data?.profile_image)),
  hasHobbies: computed(state => state.data?.hobbies?.length !== 0),
  isActiveProfile: computed(
    ({ data }) =>
      data?.available ||
      data?.all_users_visibility ||
      data?.job_watcher?.is_watcher_activated,
  ),
  setForceUserLoad: action((state, payload) => (state.forceUserLoad = payload)),
  setToken: action((state, payload) => {
    localStorage.setItem('token', payload);
    state.token = payload;
    state.error = null;
  }),
  setUserData: action((state, payload) => {
    state.data = payload;
    state.error = null;
  }),
  setUserNotifications: action((state, payload) => {
    state.notifications = payload;
  }),
  setUserNotificationsList: action((state, payload) => {
    state.notificationsList = payload;
  }),
  setMyUserLightEntrepreneurProfile: action((state, payload) => {
    console.debug('data..');
    state.lightEntrepreneurProfile = {
      data: payload,
      loading: false,
      error: false,
    };
  }),
  setMyUserLightEntrepreneurProfileError: action((state, payload) => {
    console.debug('error..');
    state.lightEntrepreneurProfile = {
      data: {},
      loading: false,
      error: true,
    };
  }),
  setMyUserLightEntrepreneurProfileLoading: action((state, payload) => {
    console.debug('loading..');
    state.lightEntrepreneurProfile = {
      data: {},
      loading: true,
      error: false,
    };
  }),

  login: thunk(
    (actions, payload, { injections }) =>
      injections.authServices
        .login(payload)
        .then(({ data }) => actions.setToken(data.token)), // 👈 dispatch local actions to update state
  ),

  twoFactorLogin: thunk(
    (actions, payload, { injections }) =>
      injections.authServices
        .twoFactorLogin(payload)
        .then(({ data }) => actions.setToken(data.token)), // 👈 dispatch local actions to update state
  ),

  // eslint-disable-next-line no-unused-vars
  logout: action(state => {
    localStorage.removeItem('token');
    state.data = null;
    state.token = null;
    state.error = null;
  }),
  getUser: thunk((actions, payload, { injections }) => {
    return injections.userService
      .getUser(payload)
      .then(({ data }) => actions.setUserData(data));
  }),
  getUserNotifications: thunk((actions, payload, { injections }) => {
    return injections.notificationService
      .httpGetMyNotifications()
      .then(({ data }) => actions.setUserNotifications(data));
  }),
  getUserNotificationsList: thunk((actions, payload, { injections }) => {
    return injections.notificationService
      .httpGetMyNotificationsList()
      .then(({ data }) => actions.setUserNotificationsList(data));
  }),
  getMyUserLightEntrepreneurProfile: thunk(
    (actions, payload, { injections }) => {
      return injections.lightEntrepreneurService
        .getMyLightEntrepreneurProfile()
        .then(({ data }) => actions.setMyUserLightEntrepreneurProfile(data))
        .catch(() => actions.setMyUserLightEntrepreneurProfileError());
    },
  ),
  patchUser: thunk((actions, payload, { injections }) =>
    injections.userService.patchUser(payload).then(({ data }) => {
      actions.setUserData(data);
    }),
  ),

  deleteUser: thunk((actions, payload, { injections }) =>
    injections.userService.deleteUser(payload).then(({ data }) => {
      actions.logout(data);
    }),
  ),
  requestActivationLink: thunk((actions, payload, { injections }) =>
    injections.userService.requestActivationLink().then(response => {
      return response;
    }),
  ),

  createExperience: thunk((actions, payload, { injections }) =>
    injections.userService
      .createExperience(payload)
      .then(() => actions.getUser()),
  ),
  patchExperience: thunk((actions, payload, { injections }) =>
    injections.userService
      .patchExperience(payload)
      .then(() => actions.getUser()),
  ),
  delteExperience: thunk((actions, payload, { injections }) =>
    injections.userService
      .delteExperience(payload)
      .then(() => actions.getUser()),
  ),
  appendUserMedia: action((state, media) => {
    state.data.media.push(media);
    state.error = null;
  }),
  createUserMedia: thunk((actions, payload, { injections }) =>
    injections.userService.createUserMedia(payload).then(response => {
      console.log('response.data', response.data);
      actions.appendUserMedia(response.data);
      return response;
    }),
  ),
  replaceUserMedia: action((state, media) => {
    state.data.media = [
      ...state.data.media.filter(({ id }) => id !== media.id),
      media,
    ];
    state.error = null;
  }),
  patchUserMedia: thunk((actions, payload, { injections }) =>
    injections.userService.patchUserMedia(payload).then(response => {
      actions.replaceUserMedia(response.data);
      return response;
    }),
  ),
  deleteUserMedia: thunk((actions, payload, { injections }) =>
    injections.userService
      .deleteUserMedia(payload)
      .then(() => actions.getUser()),
  ),
  addReferee: thunk((actions, payload, { injections }) =>
    injections.userService.addReferee(payload).then(() => actions.getUser()),
  ),
  patchReferee: thunk((actions, payload, { injections }) =>
    injections.userService.patchReferee(payload).then(() => actions.getUser()),
  ),
  deleteReferee: thunk((actions, payload, { injections }) =>
    injections.userService.deleteReferee(payload).then(() => actions.getUser()),
  ),
};
