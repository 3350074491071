import http, { getAuthHeader } from './index';

export const createLightEntrepreneurProfile = () => {
  return http({
    method: 'POST',
    url: '/light_entrepreneur/profiles/',
    headers: { ...getAuthHeader() },
  });
};

export const updateLightEntrepreneurProfile = (id, data) => {
  return http({
    method: 'PATCH',
    url: `/light_entrepreneur/profiles/${id}/`,
    headers: { ...getAuthHeader() },
    data,
  });
};

export const getMyLightEntrepreneurProfile = () => {
  return http({
    method: 'GET',
    url: `/light_entrepreneur/profiles/me/`,
    headers: { ...getAuthHeader() },
  });
};

export default {
  createLightEntrepreneurProfile,
  updateLightEntrepreneurProfile,
  getMyLightEntrepreneurProfile,
};
