import { pxToRem } from '../typography';

export default {
  root: {
    padding: 36,
  },
  title: {
    fontSize: pxToRem(32),
    color: 'inherit',
  },
  subheader: {
    fontSize: pxToRem(18),
    color: 'inherit',
  },
};
