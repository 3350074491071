/* eslint-disable no-param-reassign */
import { action, computed, thunk, persist } from 'easy-peasy';

function formatAndSortEnums(e) {
  return e
    .map(({ key, value }) => ({
      id: key,
      name: value,
    }))
    .sort((a, b) => {
      const aa = a.name.toLowerCase();
      const bb = b.name.toLowerCase();

      if (b.name === 'Muu' || b.name === 'Other') return -1;
      if (a.name === 'Muu' || a.name === 'Other') return 1;
      if (aa < bb) return -1;
      if (aa > bb) return 1;

      return 0;
    });
}

function formatEnums(e) {
  return e.map(({ key, value }) => ({
    id: key,
    name: value,
  }));
}

export default persist(
  {
    data: {},

    hobbies: computed(s => formatEnums(s.data.skills?.hobby || [])),
    allergies: computed(s => formatAndSortEnums(s.data.skills?.allergy || [])),
    computer_skills: computed(s =>
      formatEnums(s.data.skills?.computer_skill || []),
    ),
    setEnums: action((state, payload) => {
      state.data = payload;
    }),
    getEnums: thunk(
      (actions, payload, { injections }) =>
        injections.enumService
          .getEnums()
          .then(({ data }) => actions.setEnums(data)),
      // .catch(error => actions.setError(error)),
    ),
  },
  { storage: 'localStorage' },
);
