import http from './index';

const baseUrl = 'token/';
const twoWayAuthUrl = 'token/verify_otp_token/';

const defaultData = { email: '', password: '' };
export const login = (data = defaultData) =>
  http({ method: 'POST', url: baseUrl, data });

export const checkEmailAvailability = email =>
  http({ method: 'POST', url: 'check_email_availability/', data: { email } });

export const twoFactorLogin = data => {
  const requestOptions = {
    url: twoWayAuthUrl,
    method: 'POST',
    data,
  };
  return http(requestOptions);
};

export default {
  login,
  twoFactorLogin,
};
