import React from 'react';

import { useTranslation } from 'react-i18next';
import { useCookieConsent } from 'context/CookieConsentContext';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState } from 'easy-peasy';
import Link from 'components/common/Link';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cookieBanner: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: theme.palette.primary.main,
    color: 'white',
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1300',
  },
  buttonAccept: {
    background: theme.palette.success.main,
    border: 'none',
    padding: '8px 12px',
    marginLeft: '10px',
    cursor: 'pointer',
    color: 'white',
    borderRadius: '5px',
  },
  button: {
    background: theme.palette.error.main,
    border: 'none',
    padding: '8px 12px',
    marginLeft: '10px',
    cursor: 'pointer',
    color: 'white',
    borderRadius: '5px',
  },
}));

const CookieBanner = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sector = useStoreState(s => s.sector.data);
  const { consent, acceptCookies, denyCookies } = useCookieConsent();

  if (consent) return null;

  return (
    <div className={classes.cookieBanner}>
      <Typography>
        {t('cookie_banner_infotext')}
        <Link isExternal href={sector?.terms_agree}>
          &nbsp;{t('käyttöehdot')}
        </Link>
      </Typography>
      <button
        type="button"
        className={classes.buttonAccept}
        onClick={acceptCookies}
      >
        {t('Hyväksy')}
      </button>
      <button type="button" className={classes.button} onClick={denyCookies}>
        {t('Hylkää')}
      </button>
    </div>
  );
};

export default CookieBanner;
