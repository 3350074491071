/* eslint-disable no-param-reassign */
import { action, thunk, computed } from 'easy-peasy';

const initialState = {
  data: {
    count: 0,
    next: '',
    previous: 'null',
    results: [],
  },
};

/*
  getMyMeetingRequests,
  getMyMeetingRequest,
  setMeetingRequestOpened,
  acceptMeetingRequest,
  rejectMeetingRequest,
*/

export default {
  ...initialState,
  hasMoreMeetingRequests: computed(s => {
    const { count, results } = s.data;
    return count > results.length;
  }),

  setMeetingRequest: action((state, payload) => {
    state.data.results = state.data.results.map(mr =>
      mr.id === payload.id ? payload : mr,
    );
  }),

  getMeetingRequest: thunk((actions, payload, { injections }) =>
    injections.meetingRequestsService
      .getMyMeetingRequest(payload.id)
      .then(res => actions.setMeetingRequest(res.data)),
  ),

  setMeetingRequests: action((state, payload) => {
    state.data = payload;
  }),

  getMeetingRequests: thunk((actions, payload, { injections }) =>
    injections.meetingRequestsService
      .getMyMeetingRequests(payload)
      .then(res => actions.setMeetingRequests(res.data)),
  ),

  appendNextMeetingRequestsPage: action((state, payload) => {
    console.log('appendNextMeetingRequestsPage', { payload });
    const save = [...state.data.results];
    state.data = {
      ...payload,
      results: [...save, ...payload.results],
    };
  }),

  getNextMeetingRequestsPage: thunk(
    (actions, { page, rowsPerPage }, { injections, getState }) => {
      const { hasMoreMeetingRequests, data } = getState();
      const offset = (page + 1) * rowsPerPage;
      if (data.results.length <= offset && hasMoreMeetingRequests) {
        return injections.meetingRequestsService
          .getMyMeetingRequests({
            params: { offset, limit: rowsPerPage },
          })
          .then(res => actions.appendNextMeetingRequestsPage(res.data));
      }
      return Promise.resolve();
    },
  ),

  markMeetingRequestRead: action((state, payload) => {
    const { id } = payload;
    state.data.results.map(m =>
      m.id === id ? (m.new_chat_messages_count = 0) : m,
    );
  }),
  markMeetingRequestUnread: action((state, payload) => {
    const { id } = payload;
    state.data.results.map(m =>
      m.id === id ? (m.new_chat_messages_count += 1) : m,
    );
  }),

  acceptMeetingRequest: thunk((actions, payload, { injections }) =>
    injections.meetingRequestsService
      .acceptMeetingRequest(payload.id)
      .then(() => actions.getMeetingRequest(payload)),
  ),
  rejectMeetingRequest: thunk((actions, payload, { injections }) =>
    injections.meetingRequestsService
      .rejectMeetingRequest(payload.id)
      .then(() => actions.getMeetingRequest(payload)),
  ),
};
