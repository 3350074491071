import React, { useEffect, useCallback, useMemo } from 'react';
import vars from 'utils/_vars';
import io from 'socket.io-client';
import { useSnackbar } from 'notistack';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Grid } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

let { visibilityState } = document;
function onDocumentVisibilityStateChange() {
  visibilityState = document.visibilityState;
}

function makePushUrl() {
  switch (vars.api) {
    case 'local':
      return 'https://dev.profiili.io';
    case 'profiili':
      return 'https://server.profiili.io';
    case 'profiili_io':
      return 'https://server.profiili.io';
    case 'profiiliDev':
      return 'https://dev.profiili.io';
    case 'appdev-varvaamo-fi':
      return 'https://dev.profiili.io';
    default:
      throw Error(`UNKNOW API ENV:${vars.api}`);
  }
}

function getPath(type) {
  switch (type) {
    case 1:
      return '/applications/my_applications';
    case 2:
      return '/applications/open_applications';
    case 3:
      return '/applications/meeting_requests';
    default:
      return '/applications';
  }
}

// eslint-disable-next-line react/prop-types
const GotoButton = ({ path, label, closeSnackbar, key }) => {
  const { push, location } = useHistory();
  function handleClick() {
    push(path);
    closeSnackbar(key);
  }

  return (
    location.pathname !== path && (
      <Button variant="text" size="small" color="inherit" onClick={handleClick}>
        {label}
      </Button>
    )
  );
};

// eslint-disable-next-line react/prop-types
const NewMessageSnack = ({ sender, message }) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" style={{ maxWidth: 250 }}>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <MailIcon />
          </Grid>
          <Grid item xs={10}>
            <Typography noWrap>{`${t('Uusi viesti')} - ${sender} `}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography align="right" variant="caption" display="block" noWrap>
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

const makeNotification = ({ type, title, options }) => {
  if (
    Notification.permission === 'granted' &&
    document.visibilityState === 'hidden'
  ) {
    const notification = new Notification(title, options);
    notification.onclick = e => {
      e.preventDefault(); // prevent the browser from focusing the Notification's tab
      window.open(getPath(type));
      notification.close();
    };
  }
};

export default function useSocket({ token }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { open: chatOpen } = useStoreState(s => s.chat);
  const { getChat } = useStoreActions(s => s.chat);
  const { markApplicationChatUnread } = useStoreActions(s => s.applications);
  const { markMeetingRequestUnread } = useStoreActions(s => s.meetingRequest);

  const onNewMessage = useCallback(
    type => ({ message }) => {
      makeNotification({
        type,
        title: t('Uusi viesti'),
        options: {
          requireInteraction: true,
          body: `${t('Sinulle on uusi viesti henkilöltä')} ${
            message.sender.first_name
          } ${message.sender.last_name}`,
        },
      });
      console.log('new_message', message);
      const id =
        message.application?.id ||
        message.open_application?.id ||
        message.meeting_request?.id;

      if (type === 3) {
        markMeetingRequestUnread({ id });
        console.log(`mark mr ${id} unread`);
      } else {
        markApplicationChatUnread({ type, id });
      }

      getChat({
        params: {
          object_type: type,
          object_id: id,
          no_read_mark: true,
        },
      }).then(() => {
        if (chatOpen !== `${type}_${id}`) {
          enqueueSnackbar(
            <NewMessageSnack
              sender={`${message.sender.first_name} ${message.sender.last_name}`}
              message={message.txt}
            />,
            {
              action: (
                <GotoButton
                  label={t('Siirry')}
                  path={getPath(type)}
                  closeSnackbar={closeSnackbar}
                />
              ),
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            },
          );
        }
      });
    },
    [
      markMeetingRequestUnread,
      markApplicationChatUnread,
      getChat,
      chatOpen,
      enqueueSnackbar,
    ],
  );

  const onNewTrainingPosition = pushEvent => {
    console.log('onNewTrainingPosition', pushEvent);

    enqueueSnackbar(
      <NewMessageSnack
        sender={`${pushEvent?.message?.sender?.academy_name}`}
        message={pushEvent?.text}
      />,
      {
        action: (
          <GotoButton
            label={t('Siirry')}
            path={`/academy/training/${pushEvent?.message?.training_period?.id}/detail`}
            closeSnackbar={closeSnackbar}
          />
        ),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
    );
  };

  const onNewMeetingRequest = pushEvent => {
    console.log('onNewMeetingRequest', pushEvent);
    enqueueSnackbar(
      <NewMessageSnack
        sender={`${pushEvent?.message?.sender?.company_name}`}
        message={pushEvent?.message?.text}
      />,
      {
        action: (
          <GotoButton
            label={t('Siirry')}
            path="/applications/meeting_requests"
            closeSnackbar={closeSnackbar}
          />
        ),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
    );
  };

  useEffect(() => {
    // Is the tab/window visible to user
    document.addEventListener(
      'visibilitychange',
      onDocumentVisibilityStateChange,
    );

    const socket = io(makePushUrl());

    socket.on('connect', () => {
      console.log('connected for push notifications');
      socket.emit('authenticate', { token });
      if ('Notification' in window) {
        // eslint-disable-next-line no-console
        if (Notification.permission === 'default') {
          Notification.requestPermission();
        }
      }
    });

    socket.off('new_message');
    socket.on('new_message', onNewMessage(1));

    socket.off('new_open_message');
    socket.on('new_open_message', onNewMessage(2));

    socket.off('new_meeting_message');
    socket.on('new_meeting_message', onNewMessage(3));

    socket.off('new_training_position');
    socket.on('new_training_position', onNewTrainingPosition);

    socket.off('new_meeting_request');
    socket.on('new_meeting_request', onNewMeetingRequest);

    socket.on('disconnect', function(_event) {
      console.log('onSocketDisconnect');
    });

    return () => {
      console.log('socket.disconnect();');
      socket.off('new_message');
      socket.off('new_open_message');
      socket.off('new_meeting_message');
      socket.off('new_training_position');
      socket.off('new_meeting_request');

      socket.disconnect();
      document.removeEventListener(
        'visibilitychange',
        onDocumentVisibilityStateChange,
      );
    };
  }, [token]);

  return null;
}
