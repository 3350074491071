import http from './index';

const defaultProps = { params: {} };
const baseUrl = 'industries/';

export const getIndustries = ({ params } = defaultProps) =>
  http({ url: baseUrl, method: 'GET', params });

export const getSchoolIndustries = ({ params } = defaultProps) =>
  http({ url: 'school_industries/', method: 'GET', params });

export default {
  getIndustries,
  getSchoolIndustries,
};
