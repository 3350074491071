import { debug } from 'utils/_vars';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { locale } from 'moment';
import store from 'store';
// import en from '../../public/locales/en/translation.json'
const lngMap = {
  en: 'en-GB',
  fi: 'fi',
  sv: 'sv-SE',
};

i18n
  .on('languageChanged', lng => {
    console.log('languageChanged', lng);
    const { patchUser } = store.getActions().user;
    const { getEnums } = store.getActions().enums;
    const { data } = store.getState().user;

    locale(lng);

    if (data?.language && data?.language !== lng) {
      patchUser({ language: lng }).then(res => {
        window.location.reload();
      });
    }
    getEnums();
  })
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(detector)
  .use(backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    keySeparator: '|',
    debug: process.env.NODE_ENV === 'jani',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    supportedLngs: ['fi', 'en-GB', 'sv'],
    fallbackLng: ['fi', 'en-GB', 'sv'],
    backend: {
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
