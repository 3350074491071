import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';

import { Menu as MenuIcon } from '@material-ui/icons';
import AppContext from 'context/App';
import Avatar from 'components/Avatar';
import Dropdown from 'components/common/Buttons/Dropdown';
import { useTranslation } from 'react-i18next';
import FlagIcon from 'react-country-flag';
import Link from 'components/common/Link';
import LanguageSelector from 'components/LanguageSelector';
import cl from 'clsx';

const useStyles = makeStyles(theme => {
  return {
    logoImg: {
      objectFit: 'contain',
      [theme.breakpoints.only('xs')]: {
        maxHeight: 24,
      },
      maxHeight: 44,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    headerContainer: {
      width: '100%',
    },
  };
});

function Header() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const { loggedIn, data: user, hasBasicData } = useStoreState(s => s.user);
  const sector = useStoreState(s => s.sector.data);
  const { logout } = useStoreActions(a => a.user);
  const { isMobile, openSideMenu } = useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  const handleMenuClose = (val, type) => {
    console.debug('val', val, 'type', type);
    if (type === 'languageAction') {
      i18n.changeLanguage(val);
    } else if (val === 'logout') {
      logout();
    } else {
      history.push(val);
    }
  };

  useEffect(() => {
    console.debug('location', location);
  }, [location]);

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const getLangCode = lng => {
    if (lng === 'en-GB') {
      return 'gb';
    }
    if (lng === 'sv') {
      return 'se';
    }
    return lng;
  };

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Toolbar>
        <Grid className={classes.headerContainer} container alignItems="center">
          <Grid item xs>
            <Grid container alignItems="center">
              {hasBasicData && isMobile && loggedIn && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Menu"
                  onClick={openSideMenu}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {loggedIn ? (
                <img
                  className={classes.logoImg}
                  src={
                    (sector && sector.logo) ||
                    'https://dummyimage.com/256x48/fff/000?text=sektori'
                  }
                  alt="logo"
                />
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs>
            {loggedIn ? null : (
              <Grid container justify="center">
                <Link to="/intro">
                  <img
                    className={classes.logoImg}
                    src={
                      (sector && sector.logo) ||
                      'https://dummyimage.com/256x48/fff/000?text=sektori'
                    }
                    alt="logo"
                  />
                </Link>
              </Grid>
            )}
          </Grid>

          <Grid item xs>
            {location.pathname === '/register' ? null : (
              <Grid container justify="flex-end">
                {loggedIn ? (
                  <Dropdown
                    id="profile-menu"
                    buttonProps={{
                      variant: 'text',
                      color: 'inherit',
                      endIcon: (
                        <Avatar
                          src={user?.profile_image}
                          noShadows
                          size={36}
                          variant="circle"
                        />
                      ),
                      children: isXs ? '' : user?.full_name || '-',
                    }}
                    items={[
                      hasBasicData && {
                        key: t('Työpaikkatori'),
                        value: `/${sector?.name_slug}/search/jobs`,
                      },
                      { key: t('Omat tiedot'), value: '/profile/basic' },
                      { key: t('Asetukset'), value: '/settings' },
                      { key: t('Kirjaudu ulos'), value: 'logout' },
                    ]}
                    onSelect={handleMenuClose}
                  />
                ) : (
                  /* ) : (
                i18n.languages
                  .filter(lng => lng !== i18n.language)
                  .map(lng => (
                    <IconButton
                      key={lng}
                      onClick={() => i18n.changeLanguage(lng)}
                    >
                      <FlagIcon
                        svg
                        code={lng === 'en' ? 'gb' : lng}
                        styleProps={{ width: '24px', height: '24px' }}
                      />
                    </IconButton>
                  ))
              )} */
                  <Dropdown
                    id="profile-menu"
                    buttonProps={{
                      variant: 'text',
                      color: 'inherit',
                      endIcon: <MenuIcon />,
                      children: null,
                    }}
                    items={[
                      {
                        key: t('Intro'),
                        value: `/${sector?.name_slug}/intro`,
                        type: 'link',
                      },
                      {
                        key: t('Työpaikkatori'),
                        value: `/${sector?.name_slug}/search/jobs`,
                        type: 'link',
                      },
                      {
                        key: t('Kirjaudu sisään'),
                        value: `/${sector?.name_slug}/login`,
                        type: 'link',
                      },
                    ].concat(
                      i18n.languages.sort().map(lng => {
                        return {
                          key: (
                            <FlagIcon
                              key={lng}
                              svg
                              code={getLangCode(lng)}
                              styleProps={{
                                width: isMobile ? '18px' : '24px',
                                height: isMobile ? '18px' : '24px',
                              }}
                            />
                          ),
                          value: lng,
                          type: 'languageAction',
                        };
                      }),
                    )}
                    onSelect={handleMenuClose}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {};

export default Header;
