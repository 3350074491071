/* eslint-disable react/prop-types */
import React, { lazy, Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  Route,
  Redirect,
  matchPath,
  useParams,
} from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStoreState, useStoreActions } from 'easy-peasy';
import AppContext from 'context/App';

// import history from 'utils/history';
import Header from 'components/Header';
import HeaderSearch from 'components/HeaderSearch';
import PrivateRoute from 'components/PrivateRoute';
import BackgroundImage from 'components/BackgroundImage';
import Dialog from 'components/Dialog';
import { DialogContent, Grid, Typography, Box } from '@material-ui/core';
import Button from 'components/common/Buttons/Button';
import ProfiiliioLogo from 'assets/profiiliio.png';
import globalStyles from 'styles/themes/globalStyles';
import withSector from 'views/withSector';
import LoadingPage from 'components/LoadingPage';
import CookieBanner from 'components/CookieBanner/CookieBanner';

const SideMenu = lazy(() => import('components/SideMenu'));
const Main = lazy(() => import('views/Main'));
const Dev = lazy(() => import('views/Dev'));
const User = lazy(() => import('views/User'));
const Intro = lazy(() => import('views/Intro'));
const Login = lazy(() => import('views/Login'));
const Password = lazy(() => import('views/Password'));
const Register = lazy(() => import('views/Register'));
const Profile = lazy(() => import('views/Profile'));
const Settings = lazy(() => import('views/Settings'));
const Applications = lazy(() => import('views/Applications'));
const ProfileView = lazy(() => import('views/ProfileView'));
const SearchView = lazy(() => import('views/SearchView'));
const Jobs = lazy(() => import('views/Jobs'));
const Educations = lazy(() => import('views/Educations'));
const Events = lazy(() => import('views/Events'));
const Projects = lazy(() => import('views/Projects'));
const Academy = lazy(() => import('views/Academy'));
const Unions = lazy(() => import('views/Unions'));
const Companies = lazy(() => import('views/Companies'));
const Training = lazy(() => import('views/Training'));
const LightEntrepreneur = lazy(() => import('views/LightEntrepreneur'));

const useStyles = makeStyles(theme => {
  const smUp = theme.breakpoints.up('sm');
  const xsUp = theme.breakpoints.up('xs');

  return {
    main: ({ loggedIn }) => ({
      /*
      [`${xsUp} and (orientation: landscape)`]: {
        marginTop:
          theme.mixins.toolbar[`${xsUp} and (orientation: landscape)`]
            .minHeight,
      },
      [smUp]: {
        marginTop: theme.mixins.toolbar[smUp].minHeight,
      },
      // padding: theme.spacing(3),
       */
      // overflowY: 'auto',
      overflowX: 'hidden',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      // minHeight: '100%',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      // backgroundColor: loggedIn && theme.palette.background.default,
      // backgroundColor: '#F0F1F7',
    }),
    pageContent: {
      // display: 'flex',
      // flex: 1,
      // height: '100%',
    },
    flexGrow: {
      flex: 1,
    },
    logoImg: {
      opacity: 0.7,
      maxWidth: 250,
      display: 'block',
      margin: 0,
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
      },
    },
  };
});

const Views = ({ userConfirmMessage, userConfirmationDialog }) => {
  globalStyles();
  const params = useParams();
  const { t } = useTranslation();

  const { loggedIn, hasBasicData, data } = useStoreState(s => s.user);
  const sector = useStoreState(s => s.sector.data);
  const { getSector } = useStoreActions(a => a.sector);
  const { sectorTheme, isTiitus } = useContext(AppContext);
  const classes = useStyles({ loggedIn });
  const [loading, setLoading] = useState(true);

  const isXs = useMediaQuery(sectorTheme.breakpoints.only('xs'));

  // RegionSector BEGIN
  const getDefaultSector = () => {
    getSector({ identifiers: 20 }); // 20=värväämö, 1003=profiili
  };

  useEffect(() => {
    if (params?.sector) {
      getSector({ name: params?.sector }).then(response => {
        if (
          (!response || (response && response.payload === null)) &&
          sector === null
        ) {
          getDefaultSector();
        }
      });
    } else if (!sector) {
      getDefaultSector();
    }
  }, [params]);

  useEffect(() => {
    if (sector) {
      setLoading(false);
    }
  }, [sector]);
  // RegionsSector END

  const getProfiiliLogo = () => {
    if (isTiitus === 0) {
      return (
        <Grid item>
          <Grid container direction="column" justify="flex-end">
            <Grid item>
              <img
                className={classes.logoImg}
                src={ProfiiliioLogo}
                alt="logo"
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  return loading ? (
    <LoadingPage debug="Views" />
  ) : (
    <ThemeProvider theme={sectorTheme}>
      <CookieBanner />
      <Dialog
        fullWidth
        disableBackdropClick
        title={t('Haluatko varmasti poistua sivulta')}
        {...userConfirmationDialog[1]}
      >
        <DialogContent>
          <div style={{ padding: 12 }}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography align="center">{userConfirmMessage}</Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justify={isXs ? 'center' : 'space-between'}
                  spacing={2}
                >
                  <Grid item xs="auto">
                    <Button
                      size="small"
                      onClick={userConfirmationDialog[0].resolve}
                    >
                      {t('Poistu tallentamatta')}
                    </Button>
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      size="small"
                      color="success"
                      onClick={userConfirmationDialog[0].reject}
                    >
                      {t('Pysy sivulla')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

      <Switch>
        <Route path="/share/:userId" component={ProfileView} />
        <Route
          path="/"
          render={({ location }) => (
            <Fragment>
              <Switch>
                <Route path="/:sector?/search" component={HeaderSearch} />
                <Route path="/:sector?/jobs" component={HeaderSearch} />
                <Route path="/:sector?/educations" component={HeaderSearch} />
                <Route path="/:sector?/projects" component={HeaderSearch} />
                <Route path="/:sector?/events" component={HeaderSearch} />
                <Route path="/" component={Header} />
              </Switch>

              <main className={classes.main}>
                <Switch>
                  <Route path="/:sector?/search" component={SearchView} />
                  <Route path="/:sector?/jobs" component={Jobs} />
                  <Route path="/:sector?/educations" component={Educations} />
                  <Route path="/:sector?/projects" component={Projects} />
                  <Route path="/:sector?/events" component={Events} />
                  <Route path="/:sector?/companies" component={Companies} />

                  <Route>
                    <div
                      style={{
                        display: 'flex',
                        minHeight: '100%',
                        flex: 1,
                      }}
                    >
                      <PrivateRoute noRedirect path="/" component={SideMenu} />
                      {loggedIn &&
                      !matchPath(location.pathname, {
                        path: '/:sector?/password',
                        exact: false,
                      }) ? (
                        <div
                          style={{ flexGrow: 1, padding: 24, height: '100%' }}
                        >
                          <Switch>
                            <PrivateRoute path="/profile" component={Profile} />
                            {data && !hasBasicData && (
                              <Redirect to="/profile/basic" />
                            )}

                            <PrivateRoute exact path="/" component={Main} />
                            <PrivateRoute path="/dev" component={Dev} />
                            <PrivateRoute
                              path="/applications"
                              component={Applications}
                            />
                            <PrivateRoute
                              path="/settings"
                              component={Settings}
                            />
                            <PrivateRoute path="/user" component={User} />
                            <PrivateRoute
                              path="/academy/training"
                              component={Training}
                            />
                            <PrivateRoute path="/academy" component={Academy} />
                            <PrivateRoute path="/unions" component={Unions} />
                            <PrivateRoute
                              path="/light_entrepreneur"
                              component={LightEntrepreneur}
                            />
                            <Redirect to="/" />
                          </Switch>
                        </div>
                      ) : (
                        <Fragment>
                          <BackgroundImage image={sector?.image} />
                          <Switch>
                            <Route path="/:sector?/login" component={Login} />
                            <Route path="/:sector?/intro" component={Intro} />
                            <Route
                              path="/:sector?/password"
                              component={Password}
                            />
                            <Route
                              path="/:sector?/register"
                              component={Register}
                            />
                            <Redirect to="/intro" />
                          </Switch>
                        </Fragment>
                      )}
                    </div>
                  </Route>
                </Switch>
              </main>
            </Fragment>
          )}
        />
        <Redirect to="/" />
      </Switch>
    </ThemeProvider>
  );
};

export default withSector(Views);
