/* eslint-disable no-param-reassign */
import { action, thunk, computed } from 'easy-peasy';

const initialState = {
  jobWatcherJobs: {
    count: 0,
    next: '',
    previous: 'null',
    results: [],
  },
};

export default {
  ...initialState,
  hasMore: computed(s => {
    const { count, results } = s.jobWatcherJobs;
    return count > results.length;
  }),
  setjobWatcherJobs: action((state, payload) => {
    state.jobWatcherJobs = payload;
  }),
  appendNextPage: action((state, payload) => {
    state.jobWatcherJobs = {
      ...payload,
      results: state.jobWatcherJobs.results.concat(payload.results),
    };
  }),
  setJobWatcherJob: action((state, payload) => {
    state.jobWatcherJobs.results = state.jobWatcherJobs.results.map(a =>
      a.id === payload.id ? { ...a, ...payload } : a,
    );
  }),
  getJobWatcherJobs: thunk((actions, payload, { injections }) =>
    injections.jobWatcherService
      .getJobWatcherJobs(payload)
      .then(({ data }) => actions.setjobWatcherJobs(data)),
  ),
  getNextJobWatcherJobsPage: thunk(
    (actions, { page, rowsPerPage }, { injections, getState }) => {
      const { hasMorejobWatcherJobs, jobWatcherJobs } = getState();
      const offset = (page + 1) * rowsPerPage;
      if (jobWatcherJobs.results.length <= offset && hasMorejobWatcherJobs) {
        return injections.jobWatcherService
          .getJobWatcherJobs({
            params: { offset, limit: rowsPerPage },
          })
          .then(({ data }) => actions.appendNextjobWatcherJobsPage(data));
      }
      return Promise.resolve();
    },
  ),
};
