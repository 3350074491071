import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import spacing from './spacing';
import overrides from './overrides';
import typography from './typography';
import palette from './palette';

export const defaultTheme = responsiveFontSizes(
  createMuiTheme({
    overrides,
    typography,
    palette,
    spacing,
  }),
);

export const setSectorTheme = region =>
  responsiveFontSizes(
    createMuiTheme({
      overrides,
      typography,
      palette: region?.theme?.palette
        ? { ...palette, ...region.theme.palette }
        : { ...palette },
      spacing,
    }),
  );

export const setSectorDarkTheme = region =>
  responsiveFontSizes(
    createMuiTheme({
      overrides,
      typography,
      palette: region?.theme?.palette
        ? { ...palette, ...region.theme.palette, type: 'dark' }
        : { ...palette, type: 'dark' },
      spacing,
    }),
  );
