import http, { getAuthHeader } from './index';

const baseUrl = '/messages/';

export const getChatMessages = ({ params } = {}) =>
  http({
    method: 'GET',
    url: baseUrl,
    params: { ...params },
    headers: { ...getAuthHeader() },
  });

export const newChatMessage = ({ params, data }) =>
  http({
    method: 'POST',
    url: baseUrl,
    params: { ...params },
    headers: { ...getAuthHeader() },
    data,
  });

export default {
  getChatMessages,
  newChatMessage,
};
