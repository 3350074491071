import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import Grow from '@material-ui/core/Grow';
// import Paper from '@material-ui/core/Paper';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/common/Buttons/Button';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

function Dropdown(props) {
  const { items, id, buttonProps, onSelect } = props;
  const { i18n } = useTranslation();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleClose = () => setAnchorEl(null);

  const makeSelectHandler = (value, type) => () => {
    handleClose();
    onSelect(value, type);
  };

  return (
    <div className={classes.root}>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        {...buttonProps}
      />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, idx) => {
          const { key, value, type } = item;
          const keyProp = `${type}_${idx}`;
          return (
            <MenuItem
              selected={i18n.language === value}
              disabled={i18n.language === value}
              onClick={makeSelectHandler(value, type)}
              key={keyProp}
            >
              {key}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

Dropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Dropdown;
