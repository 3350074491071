import http, { getAuthHeader } from './index';

const baseUrl = '/job_watchers/';

const defaultPagination = {
  offset: 0,
  limit: 5,
  ordering: '-created_datetime',
};

export const getJobWatcherJobs = ({ params } = {}) =>
  http({
    method: 'GET',
    params: { ...defaultPagination, ...params },
    url: `${baseUrl}job_watcher_jobs/`,
    headers: { ...getAuthHeader() },
  }).then(res => {
    return res;
  });

export default {
  getJobWatcherJobs,
};
